import React from "react";
import bgVid from "../static/videos/beach-vid.mp4";
import beachImg from "../images/beach-img.jpeg";


// List of properties (props)

	// src = the video source
	// poster = the first frame of the video, as an imported object or relative URL
	// srcType = "video" (default and undefined) or "gif". Handles the sources. (Optional, defaults to video)
	// start = a trigger to start the video (optional) 
	// 
	// 
	// 

const defaultSource = "../static/videos/beach-vid.mp4";

const VideoBG = props => {

	let poster;
	if (!props.poster){
		poster = props.poster;
	}else{
		poster = {beachImg};
	}
	return(

		<>	
			<div id="background-video-container" 
			style={{ // Note I couldn't figure out how to pass props down into the style const so I did it here.
				position: "absolute",
			    top: "0",
			    margin: "auto",
			    width: "100%",
			    maxWidth: "100vw",
			    height: "100%",
			    maxHeight: "100vh",
			    overflow: "hidden",
			    zIndex: 0,
			    backgroundImage: `url(${props.poster})`,
			    backgroundSize: "cover",
			    backgroundPosition: "center",
			}} >
				{(props.start || props.start == undefined) && 
					<>{(props.srcType === "video" || !props.srcType) &&
						<video
							id="background-video"
							style={vidStyle}
							poster={poster}
							src={props.src || bgVid} 
							type={props.type || "video/mp4"}
							muted
							play="true"
							autoPlay
						/>}
					{props.srcType === "gif" &&
						<img
							src={props.src}
							style={vidStyle}
							id="gif-background"
						/>
					}
					</>
				}
				

				{/* POSTER/BG Image*/}
				{/*<img 
					src={props.poster || beachImg}
					style={vidStyle}
					id="background-image"
				/>*/}

			</div>
		</>
	)
}

/*const vidContainerStyle = {
	position: "absolute",
    top: "0",
    margin: "auto",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 0,
    background: "url({props.poster})",
    backgroundSize: "cover",
	backgroundPosition: "center",
}*/

const vidStyle = {
	minWidth: "100%",
	minHeight: "100%",
	position: "relative",
	marginLeft: "50%",
	transform: "translateX(-50%)",
}


const NEWTRYvidStyle = {
	height: "100%",
    width: "177.77777778vh", /* 100 * 16 / 9 */
    minWidth: "100%",
    minHeight: "56.25vw", /* 100 * 9 / 16 */
    position: "absolute",
	// top: "50%",
	marginLeft: "50%",
	transform: "translateX(-50%)",
}

export default VideoBG;