import React from "react";
import styled from "styled-components";
 
function Timer(props) {
  return (
    <TimerWrapper className="timer">

      {/* MINUTES */}
      <span className="digits">
        {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
      </span>

      {/* SECONDS */}
      <span className="digits">
        {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
      </span>
      
      {/* MILLISECONDS */}
      {/*
      <span className="digits mili-sec">
        .{("0" + ((props.time / 10) % 100)).slice(-2)}
      </span>
      */}

    </TimerWrapper>
  );
}

export default Timer;

const TimerWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
`